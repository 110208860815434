/* eslint-disable */

// 测试服务器 67
function yidianling_67() {
  return {
    brand: "YIDIANLING", //品牌
    API_ADDRESS: "http://47.122.4.67:60314", //服务器地址
    WSS_ADDRESS: "ws://47.122.4.67:60314/myWs", //websocket服务器地址
    PRINT_ADDRESS: "http://47.122.4.67:23130", //服务器地址
    AI_ADDRESS: "https://ai-api.yingyutifen.cn:8443", //AI服务器地址
  };
}

//李校来啦 Ai4.0
function liXiao_ai4_151() {
  return {
    brand: "LIXIAO", //品牌
    hostname: "127.0.0.1", //域名
    API_ADDRESS: "https://ai4-prod-api.yingyutifen.cn:8443", //服务器地址
    WSS_ADDRESS: "wss://ai4-prod-api.yingyutifen.cn:8443/myWs", //websocket服务器地址
    PRINT_ADDRESS: "https://ai4-prod-print.yingyutifen.cn", //服务器地址
    AI_ADDRESS: "https://ai-api.yingyutifen.cn:8443", //AI服务器地址
  };
}

//李校来啦 Ai5.0预生产环境
function liXiao_ai5_Pre() {
  return {
    brand: "LIXIAO", //品牌
    hostname: "lx5-admin.yingyutifen.cn", //域名
    API_ADDRESS: "https://lx5-api.yingyutifen.cn", //服务器地址
    WSS_ADDRESS: "wss://lx5-api.yingyutifen.cn/myWs", //websocket服务器地址
    PRINT_ADDRESS: "http://lx5-print.yingyutifen.cn", //打印地址
    AI_ADDRESS: "https://ai-api.yingyutifen.cn:8443", //AI服务器地址
  };
}

//李校来啦-暂时不用，可以当测试环境
function liXiao_test() {
  return {
    brand: "LIXIAO", //品牌
    hostname: "127.0.0.1", //域名 作为OEM的查询条件
    API_ADDRESS: "http://47.110.141.208:24071", //服务器地址
    //API_ADDRESS: "http://lx-ai-demo.yingyutifen.cn",
    WSS_ADDRESS: "ws://lx-ai-demo.yingyutifen.cn/myWs", //websocket服务器地址
    PRINT_ADDRESS: "http://47.110.141.208:23131", //服务器地址
    AI_ADDRESS: "http://lx-ai-server-demo.yingyutifen.cn:6039", //AI服务器地址
  };
}

//李校来啦 测试 208
function liXiao_test_dev() {
  return {
    brand: "LIXIAO", //品牌
    hostname: "127.0.0.1", //域名 作为OEM的查询条件
    API_ADDRESS: "http://47.110.141.208:24071", //服务器地址
    WSS_ADDRESS: "ws://47.110.141.208:24071/myWs", //websocket服务器地址
    PRINT_ADDRESS: "http://47.110.141.208:23131", //服务器地址
    AI_ADDRESS: "http://lx-ai-server-demo.yingyutifen.cn:6039", //AI服务器地址
  };
}

//本地服务器
function local() {
  return {
    brand: "LIXIAO", //品牌
    hostname: "47.110.141.208", //域名
    API_ADDRESS: "http://192.168.5.193:8443", //服务器地址
    WSS_ADDRESS: "ws://192.168.5.193:8443/myWs", //websocket服务器地址
    PRINT_ADDRESS: "http://47.110.141.208:23130", //服务器地址
    AI_ADDRESS: "http://127.0.0.1:6039", //AI服务器地址
  };
}

//133微软AI测试服务器
function lixiao_AzureAI_test() {
  return {
    brand: "LIXIAO", //品牌
    hostname: "47.110.141.208", //域名
    API_ADDRESS: "http://47.110.141.208:60320", //服务器地址
    WSS_ADDRESS: "ws://47.110.141.208:60320/myWs", //websocket服务器地址
    PRINT_ADDRESS: "http://47.110.141.208:23131", //服务器地址
    AI_ADDRESS: "http://120.55.39.133:6039", //AI服务器地址
  };
}

//133微软AI 演示环境--目前正在使用
function lixiao_EDU_AND_AZUREAI_TEST() {
  return {
    brand: "LIXIAO", //品牌
    hostname: "120.55.39.133", //域名
    API_ADDRESS: "http://120.55.39.133:60320", //服务器地址
    WSS_ADDRESS: "ws://120.55.39.133:60320/myWs", //websocket服务器地址
    PRINT_ADDRESS: "http://47.110.141.208:23131", //服务器地址
    AI_ADDRESS: "http://120.55.39.133:6039", //AI服务器地址
  };
}

//朱涛 本地服务器
function zhutao_local() {
  return {
    brand: "LIXIAO", //品牌
    hostname: "127.0.0.1", //域名
    API_ADDRESS: "http://192.168.5.60:8443", //服务器地址
    WSS_ADDRESS: "ws://192.168.5.60:8443/myWs", //websocket服务器地址
    PRINT_ADDRESS: "http://47.110.141.208:23130", //服务器地址
    AI_ADDRESS: "http://192.168.5.60:6039", //AI服务器地址
  };
}

//重庆新测试环境
function server_5() {
  return {
    brand: "LIXIAO", //品牌
    hostname: "47.96.254.138", //域名
    API_ADDRESS: "http://113.250.13.197:34070", //服务器地址
    WSS_ADDRESS: "ws://113.250.13.197:34070/myWs", //websocket服务器地址
    PRINT_ADDRESS: "http://113.250.13.197:34071", //服务器地址
    AI_ADDRESS: "http://lx-ai-server-demo.yingyutifen.cn:6039", //AI服务器地址
  };
}

//重庆新测试环境
function chenshuai_test() {
  return {
    brand: "LIXIAO", //品牌
    hostname: "127.0.0.1", //域名
    API_ADDRESS: "http://192.168.5.148:24071", //服务器地址
    WSS_ADDRESS: "ws://47.96.254.137:24071/myWs", //websocket服务器地址
    PRINT_ADDRESS: "http://47.96.254.137:23131", //服务器地址
    AI_ADDRESS: "http://lx-ai-server-demo.yingyutifen.cn:6039", //AI服务器地址
  };
}

export default {
  config: liXiao_ai5_Pre(),
  // config: server_5(),
  version: "5.0.7-Beta", //APP版本
};
